/**
 * The root Cloudinary folder to use.
 */
const root: string = useRuntimeConfig().public.cloudinaryId;

/**
 * Generate an image link relative to the root Cloudinary folder location.
 *
 * @param path
 */
export const cloudinaryUrl = (path: string): string =>
    // Generate the full image URL using the root Cloudinary folder location
    `https://res.cloudinary.com/enjovia/image/upload/${root}/assets/dashboard${normalisePath(
        path,
    )}`;

/**
 * Prepare the Cloudinary upload widget combining passed options with common upload options.
 *
 * @param uploadCallback
 * @param options
 */
export const prepareWidget = (uploadCallback: Function, options: object = {}): unknown => {
    const auth = useAuthStore();

    // Ensure a user is set
    if (!auth.user) {
        throw new Error('Must be logged in to prepare a Cloudinary widget.');
    }

    const accountsStore = useAccountStore();

    // Ensure an account is set
    if (!accountsStore.account) {
        throw new Error(
            'Must be operating in an account to prepare a Cloudinary widget.',
        );
    }

    // @ts-ignore
    return cloudinary.createUploadWidget(
        {
            cloudName: 'enjovia',
            // The upload preset is used to define settings for uploaded images
            uploadPreset: 'client-upload',
            // Set the Cloudinary folder where uploaded images are stored
            folder: `${root}/client/${accountsStore.account.id}`,
            // Context is additional metadata attached to the uploaded image
            // We attach the ID of the user responsible for uploading the image
            context: { upload_user: auth.user.id },
            // Disable uploading of multiple images
            multiple: false,
            // Allow cropping of images before completing upload
            cropping: true,
            ...options,
        },
        // @ts-ignore
        (error, result): void => {
            // If the upload is successful
            if (!error && result && result.event === 'success') {
                // Pass the uploaded image URL to the upload callback
                uploadCallback(result.info.secure_url);
            }
        },
    );
};
